<template>
  <span>
    ...redirect to {{ url }}
  </span>
</template>

<script>
import helperService from "@/services/helperService.js";
export default {
  components: {
  },
  data() {
    return {
      url: "",
      formData: {}
    };
  },

  created() {
    this.getURL();
  },

  methods: {
    getURL() {
      
        this.url = window.location.href;
        
        if (this.url) {
          let url =  new URL(this.url);
          let params = url.searchParams; 
          if (params.get('redirect_service') == "line_notify") {
            
            this.formData.proSerial = params.get('proSerial')
            this.formData.notiType = params.get('notiType')
            try {
              helperService.insertLogNoti(this.formData)
              .then((response) => {
                if (response.data.status == 200) {
                  console.log("insert successfully")

                  this.url = "https://notify-bot.line.me/oauth/authorize?response_type=code&client_id=1TZyp8BlQESTnqXo82ftQj&redirect_uri="+process.env.VUE_APP_API_URL+"notify-callback&scope=notify&state="+params.get('proSerial')+"%7C"+params.get('notiType');
                  window.location = this.url
                }
              }).catch();
            } catch (e) {
              throw e;
            }
            
           
          }
        }
      
    },
  }
}
</script>

<style>

</style>
